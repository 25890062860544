<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="topic1ImageContainer">
          <img class="topicImage" :src="topicImage" />
        </div>
        <div class="titleContainer bg-green mt-30">
          <p class="titleText">{{ $t("labels.community") }}</p>
        </div>
        <div class="subContainer p-0 mb-20">
          <div
            v-for="(item, index) in getTopics()"
            :key="index"
            :class="`btn rowContainer text-center ${
              index < getTopics().length ? 'border' : ''
            }`"
            @click="goToOutsideLink(item.url)"
          >
            <p class="secondaryTitleText">{{ item.title }}</p>
          </div>
        </div>
        <div
          v-for="(item, index) in getSortedForums()"
          :key="index"
          class="filterBtn btn bg-blue"
          @click="goToOutsideLink(item.url)"
        >
          <p class="filterBtnText">{{ item.title }}</p>
        </div>
        <div class="filterBtn community bg-green btn">
          <p class="filterBtnText">{{ $t("labels.goToForum") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";
import CustomHeader from "../components/CustomHeader.vue";
export default {
  name: "ForumPage",
  components: {
    CustomHeader,
  },
  data() {
    return {
      topicImage: require("../assets/image/png/people.png"),
    };
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    goToLogin() {
      this.$router.push("/login");
    },
    goToOutsideLink(url) {
      window.open(url, "_blank");
    },
    getTopics() {
      let sortedForums = [];
      for (let i = 0; i < this.forums.length; i++) {
        let item = this.forums[i];
        if (
          item.id === 10 ||
          item.id === 11 ||
          item.id === 12 ||
          item.id === 0 ||
          item.id === 1 ||
          item.id === 2 ||
          item.id === 3
        )
          continue;
        sortedForums.push(item);
      }
      console.log(sortedForums);
      return sortedForums;
    },
    getSortedForums() {
      let sortedForums = [];
      if (this.forums.length == 0) return [];
      for (let i = 3; i > 0; i--) {
        sortedForums.push(this.forums.find((x) => x.id === i));
      }

      console.log(sortedForums);
      return sortedForums;
    },
  },
  //   watch: {
  //     name: {
  //       handler(newVal, oldVal) {
  //         console.log(newVal);
  //         console.log(oldVal);
  //       },
  //       immediate: true,
  //     },
  //   },
  computed: mapState({
    forums: (state) => state.main.forums,
  }),
  mounted() {
    this.$store.dispatch("main/getForumsAttempt");
  },
};
</script>

<style scoped>
.subTitleText {
  color: var(--textColor);
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}
.topicText {
  font-size: 18px;
  text-align: center;
  color: black;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
.desc {
  background-color: rgb(241, 242, 242);
  font-size: 16px;
  color: var(--textColor);
  padding: 10px;
  margin: 10px;
  margin-bottom: 0px;
  flex: 1;
}
.divider {
  margin-top: 30px;
  height: 1px;
  background-color: lightgray;
  width: 100%;
}
.topic1ImageContainer {
  width: 100%;
  padding-top: 52.25%;
  position: relative;
  overflow: hidden;
}
.topicImage {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
}
.rowContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}
.topic2ImageContainer {
  width: 25%;
  padding-top: 25%;
  position: relative;
  overflow: hidden;
}
.m-0 {
  margin: 0px;
}
.hp-100 {
  height: 100%;
  flex: 1;
}
.border {
  border-bottom: 1px solid lightgray;
}
.secondaryTitleText {
  font-size: 25px;
  color: var(--textColor);
}
.filterBtn {
  width: 80%;
  padding: 10px 0px;
  align-self: center;
  border-radius: 10px;
  box-shadow: 0 2px 8px 0 lightgray, 0 2px 8px 0 lightgray;
  margin-top: 20px;
}
.filterBtnText {
  color: white;
  font-size: 18px;
  text-align: center;
}
.community {
  width: 90%;
  margin-top: 50px;
}
</style>